/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
define([
  'main/project-list/project-list',
  'main/project-list/modal-controllers',
  'main/project-list/tag-controllers',
  'main/project-list/notifications-controller',
  'main/project-list/left-hand-menu-promo-controller',
  'services/queued-http'
], function() {})
